<template>
    <v-container fluid class="fill-height primary">
        <v-row no-gutters align="center" justify="center">
            <v-col cols="12" sm="8" md="6" lg="4">
                <v-form autocomplete="on" @submit="register">
                    <v-card class="elevation-5 pa-3">
                        <div class="text-right">
                            <localization />
                        </div>
                        <v-card-text>
                            <v-snackbar v-model="showResult" :timeout="30000" top :color="color" tile>
                                {{ result }}
                            </v-snackbar>
                            <div class="layout column align-center">
                                <img src="img/icons/logo.png" alt="Logo Vitissia" width="241" height="115">
                                <h1 class="text-center my-4 primary--text">
                                    Enregistrer les informations de votre société
                                </h1>
                            </div>

                            <v-text-field v-model="model.siret" name="siret" label="Numéro de siret" required
                                @input="siret" />
                            <v-row>
                                <v-col>
                                    <v-text-field v-model="model.name" name="nom_entreprise"
                                        label="Nom de votre entreprise" />
                                </v-col>
                                <v-col>
                                    <v-text-field v-model="model.nameCom" name="nom_commercial"
                                        label="Nom Commercial (si différent)" />
                                </v-col>
                            </v-row>
                            <v-text-field v-model="model.email" append-icon="mdi-account" name="email"
                                :rules="[rules.required, rules.email]" :label="$t('login.email')" type="email" />
                            <vue-tel-input-vuetify v-model="model.phone" mode="international" placeholder=""
                                label="Téléphone" :preferred-countries="['fr', 'gb']" />
                            <v-text-field v-model="model.site" name="site_entreprise" label="Site web" />
                            <v-text-field v-model="model.adresse" name="adresse_entreprise" label="Adresse" />
                            <v-row>
                                <v-col>
                                    <v-text-field v-model="model.cp" name="cp_entreprise" label="Code postal" />
                                </v-col>
                                <v-col>
                                    <v-text-field v-model="model.ville" name="ville_entreprise" label="Ville" />
                                </v-col>
                            </v-row>
                            <v-text-field v-model="model.siren" name="siren" label="Numéro de siren" />
                            <v-row>
                                <v-col>
                                    <v-text-field v-model="model.statut" name="statut_entreprise"
                                        label="Statut juridique" />
                                </v-col>
                                <v-col>
                                    <v-text-field v-model="model.categorie" name="catégorie_entreprise"
                                        label="Catégorie juridique" />
                                </v-col>
                            </v-row>
                            <v-text-field v-model="model.tva" name="tva_entreprise" label="Numéro de TVA" />
                            <v-text-field v-model="model.ape" name="ape_entreprise" label="APE" />
                        </v-card-text>
                        <v-card-actions>
                            <v-row>
                                <v-col cols="12">
                                    <v-btn type="submit" color="primary" :loading="loading" block>
                                        Valider
                                    </v-btn>
                                </v-col>
                                <v-col cols="12">
                                    <v-btn color="primary" outlined to="/signin" block>
                                        Créer mon compte professionel plus tard
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-card-actions>
                    </v-card>
                </v-form>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import Localization from '../widget/AppLocalization.vue';

export default {
    name: 'SocieteSignUp',
    components: { Localization },
    data: () => ({
        loading: false,
        showResult: false,
        result: '',
        color: 'undefined',
        model: {
            email: '',
            nameCom: '',
            name: '',
            phone: '',
            siret: '',
            siren: '',
            tva: '',
            adresse: '',
            cp: '',
            ville: '',
            ape: '',
            statut: '',
            categorie: '',
            site: '',
            pays: '',
        },
        rules: {
            required: (value) => !!value || 'Obligatoire.',
            email: (value) => {
                const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return pattern.test(value) || 'e-mail invalide.';
            },
        }
    }),
    mounted() {
        this.uuid = this.$route.query.uuid;
    },
    methods: {
        async register(e) {
            e.preventDefault();
            const vm = this;
            if (vm.model.email === '') {
                vm.showResult = true;
                vm.color = 'red';
                vm.result = 'Veuillez renseigner les champs nécessaires';
                return;
            }
            const url = `${vm.$config.API_ENDPOINT}/4DACTION/ksf_sys_web_registerCompany`;
            const bodyFormData = new FormData();
            bodyFormData.append('nom_entreprise_register', vm.model.name);
            bodyFormData.append('nomCom_entreprise_register', vm.model.nameCom);
            bodyFormData.append('email_entreprise_register', vm.model.email);
            bodyFormData.append('site_entreprise_register', vm.model.site);
            bodyFormData.append('phone', vm.model.phone);
            bodyFormData.append('adresse1_entreprise_register', vm.model.adresse);
            bodyFormData.append('cp_entreprise_register', vm.model.cp);
            bodyFormData.append('ville_entreprise_register', vm.model.ville);
            bodyFormData.append('numero_siret_register', vm.model.siret);
            bodyFormData.append('numero_siren_register', vm.model.siren);
            bodyFormData.append('statut_entreprise_register', vm.model.statut);
            bodyFormData.append('ape_entreprise', vm.model.ape);
            bodyFormData.append('numtva_entreprise_register', vm.model.tva);
            bodyFormData.append('categorie', vm.model.categorie);
            bodyFormData.append('useruuid', vm.uuid);

            const response = await vm.$axios.post(url, bodyFormData);
            const result = response.data;

            if (result.header === 'Succès') {
                vm.result = 'Votre société a bien été enregistrée';
                vm.color = 'green';
                vm.showResult = true;

                setTimeout(() => {
                    this.$router.push(this.$route.query.redirect || '/');
                }, 5000);
            } else {
                vm.error = true;
                vm.result = 'Certains champs ne peuvent pas ëtre vide.';
                vm.showResult = true;
            }
        },
        async siret() {
            const vm = this;
            if (vm.model.siret.length === 14) {
                const url = `${vm.$config.API_ENDPOINT}/4DACTION/ksf_sys_api_siret`;
                const bodyFormData = new FormData();
                bodyFormData.append('numero_siret', vm.model.siret);
                const response = await vm.$axios.post(url, bodyFormData);
                const result = response.data;
                if (result.header === 'Succès') {
                    vm.model.siren = result.siretreponse.etablissement.siren;
                    vm.model.ville = result.siretreponse.etablissement.adresseEtablissement.libelleCommuneEtablissement;
                    vm.model.adresse = `${result.siretreponse.etablissement.adresseEtablissement.numeroVoieEtablissement} ${result.siretreponse.etablissement.adresseEtablissement.typeVoieEtablissement} ${result.siretreponse.etablissement.adresseEtablissement.libelleVoieEtablissement}`;
                    vm.model.name = result.siretreponse.etablissement.uniteLegale.denominationUniteLegale;
                    vm.model.cp = result.siretreponse.etablissement.adresseEtablissement.codePostalEtablissement;
                }
            }
        }
    },
};
</script>
