var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "fill-height primary", attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        { attrs: { "no-gutters": "", align: "center", justify: "center" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "8", md: "6", lg: "4" } },
            [
              _c(
                "v-form",
                { attrs: { autocomplete: "on" }, on: { submit: _vm.register } },
                [
                  _c(
                    "v-card",
                    { staticClass: "elevation-5 pa-3" },
                    [
                      _c(
                        "div",
                        { staticClass: "text-right" },
                        [_c("localization")],
                        1
                      ),
                      _c(
                        "v-card-text",
                        [
                          _c(
                            "v-snackbar",
                            {
                              attrs: {
                                timeout: 30000,
                                top: "",
                                color: _vm.color,
                                tile: "",
                              },
                              model: {
                                value: _vm.showResult,
                                callback: function ($$v) {
                                  _vm.showResult = $$v
                                },
                                expression: "showResult",
                              },
                            },
                            [_vm._v(" " + _vm._s(_vm.result) + " ")]
                          ),
                          _c(
                            "div",
                            { staticClass: "layout column align-center" },
                            [
                              _c("img", {
                                attrs: {
                                  src: "img/icons/logo.png",
                                  alt: "Logo Vitissia",
                                  width: "241",
                                  height: "115",
                                },
                              }),
                              _c(
                                "h1",
                                {
                                  staticClass: "text-center my-4 primary--text",
                                },
                                [
                                  _vm._v(
                                    " Enregistrer les informations de votre société "
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _c("v-text-field", {
                            attrs: {
                              name: "siret",
                              label: "Numéro de siret",
                              required: "",
                            },
                            on: { input: _vm.siret },
                            model: {
                              value: _vm.model.siret,
                              callback: function ($$v) {
                                _vm.$set(_vm.model, "siret", $$v)
                              },
                              expression: "model.siret",
                            },
                          }),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      name: "nom_entreprise",
                                      label: "Nom de votre entreprise",
                                    },
                                    model: {
                                      value: _vm.model.name,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.model, "name", $$v)
                                      },
                                      expression: "model.name",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      name: "nom_commercial",
                                      label: "Nom Commercial (si différent)",
                                    },
                                    model: {
                                      value: _vm.model.nameCom,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.model, "nameCom", $$v)
                                      },
                                      expression: "model.nameCom",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c("v-text-field", {
                            attrs: {
                              "append-icon": "mdi-account",
                              name: "email",
                              rules: [_vm.rules.required, _vm.rules.email],
                              label: _vm.$t("login.email"),
                              type: "email",
                            },
                            model: {
                              value: _vm.model.email,
                              callback: function ($$v) {
                                _vm.$set(_vm.model, "email", $$v)
                              },
                              expression: "model.email",
                            },
                          }),
                          _c("vue-tel-input-vuetify", {
                            attrs: {
                              mode: "international",
                              placeholder: "",
                              label: "Téléphone",
                              "preferred-countries": ["fr", "gb"],
                            },
                            model: {
                              value: _vm.model.phone,
                              callback: function ($$v) {
                                _vm.$set(_vm.model, "phone", $$v)
                              },
                              expression: "model.phone",
                            },
                          }),
                          _c("v-text-field", {
                            attrs: {
                              name: "site_entreprise",
                              label: "Site web",
                            },
                            model: {
                              value: _vm.model.site,
                              callback: function ($$v) {
                                _vm.$set(_vm.model, "site", $$v)
                              },
                              expression: "model.site",
                            },
                          }),
                          _c("v-text-field", {
                            attrs: {
                              name: "adresse_entreprise",
                              label: "Adresse",
                            },
                            model: {
                              value: _vm.model.adresse,
                              callback: function ($$v) {
                                _vm.$set(_vm.model, "adresse", $$v)
                              },
                              expression: "model.adresse",
                            },
                          }),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      name: "cp_entreprise",
                                      label: "Code postal",
                                    },
                                    model: {
                                      value: _vm.model.cp,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.model, "cp", $$v)
                                      },
                                      expression: "model.cp",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      name: "ville_entreprise",
                                      label: "Ville",
                                    },
                                    model: {
                                      value: _vm.model.ville,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.model, "ville", $$v)
                                      },
                                      expression: "model.ville",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c("v-text-field", {
                            attrs: { name: "siren", label: "Numéro de siren" },
                            model: {
                              value: _vm.model.siren,
                              callback: function ($$v) {
                                _vm.$set(_vm.model, "siren", $$v)
                              },
                              expression: "model.siren",
                            },
                          }),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      name: "statut_entreprise",
                                      label: "Statut juridique",
                                    },
                                    model: {
                                      value: _vm.model.statut,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.model, "statut", $$v)
                                      },
                                      expression: "model.statut",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      name: "catégorie_entreprise",
                                      label: "Catégorie juridique",
                                    },
                                    model: {
                                      value: _vm.model.categorie,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.model, "categorie", $$v)
                                      },
                                      expression: "model.categorie",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c("v-text-field", {
                            attrs: {
                              name: "tva_entreprise",
                              label: "Numéro de TVA",
                            },
                            model: {
                              value: _vm.model.tva,
                              callback: function ($$v) {
                                _vm.$set(_vm.model, "tva", $$v)
                              },
                              expression: "model.tva",
                            },
                          }),
                          _c("v-text-field", {
                            attrs: { name: "ape_entreprise", label: "APE" },
                            model: {
                              value: _vm.model.ape,
                              callback: function ($$v) {
                                _vm.$set(_vm.model, "ape", $$v)
                              },
                              expression: "model.ape",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-card-actions",
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12" } },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        type: "submit",
                                        color: "primary",
                                        loading: _vm.loading,
                                        block: "",
                                      },
                                    },
                                    [_vm._v(" Valider ")]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "12" } },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        color: "primary",
                                        outlined: "",
                                        to: "/signin",
                                        block: "",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " Créer mon compte professionel plus tard "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }